


import { useMemo, useState, useCallback } from 'react';
import { closeSnackbar, enqueueSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Grid, Alert, Switch, TextField, Typography, DialogActions } from '@mui/material';

import { localStorageGetItem } from 'src/utils/local-storage';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { ExportFieldType } from 'src/services/organisation/organisation.types';
import { useGetExportSettingsQuery } from 'src/services/candidates/candidates.service';

type Props = {
  profileId: string;
  open: boolean;
  onClose: () => void;
};

export default function ProfileExporter({ profileId, open, onClose }: Props) {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();

  const [coverPageEnabled, setCoverPageEnabled] = useState(true);
  const [fieldValues, setFieldValues] = useState<{ [key: string]: string }>({});
  const [exportLoading, setExportLoading] = useState(false);

  const { currentData: exportSettings, isLoading: isLoadingExportSettings } = useGetExportSettingsQuery(
    profileId,
    {
      skip: !profileId || !open,
    }
  );

  const freeTextFields = useMemo(() => {
    if (!Array.isArray(exportSettings?.exporter_cover_field_mapping)) return [];

    return exportSettings?.exporter_cover_field_mapping.filter((field) => field.field_type === ExportFieldType.FREE_TEXT);
  }, [exportSettings]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const updateFieldValue = useCallback((label: string, value: string) => {
    setFieldValues((prev) => ({
      ...prev,
      [label]: value,
    }));
  }, []);

  const handleProfileExport = useCallback(async () => {

    if (!profileId) return;


    setExportLoading(true);

    const toastId = enqueueSnackbar(t('candidates.export.api.loading'), {
      variant: 'loading',
      persist: true
    });

    try {

      // get the token from local storage
      const token = localStorageGetItem('access_token', undefined);

      // use fetch instead of rtk (doesn't fully support file download)
      const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/candidates/profiles/${profileId}/export`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
        },
        body: JSON.stringify({
          cover_page_enabled: coverPageEnabled,
          cover_page_fields: freeTextFields.map((field) => ({
            label: field.label,
            value: fieldValues[field.label] || ''
          }))
        })
      });

      if (!response.ok) {
        throw new Error('Failed to download');
      }

      // get the blob from the response
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${profileId}_profile.pdf`); // Or use candidate name if available

      document.body.appendChild(link);
      link.click();

      // cleanup
      link.remove();
      window.URL.revokeObjectURL(url);

      closeSnackbar(toastId);
      setExportLoading(false);
      enqueueSnackbar(t('candidates.export.api.success'), { variant: 'success' });
      handleClose();
    }
    catch (error) {
      closeSnackbar(toastId);
      setExportLoading(false);
      enqueueSnackbar(t('candidates.export.api.default_error'), { variant: 'error' });
    }

  }, [profileId, t, coverPageEnabled, freeTextFields, fieldValues, handleClose]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>{t('profile.exporter.title')}</DialogTitle>

      <DialogContent>

        <Stack spacing={2} sx={{ pb: 2 }}>

          <Box>
            <Alert severity="info" icon={false} variant='outlined'>
              {t('profile.exporter.alert')}
            </Alert>
          </Box>

          <Box sx={{ px: 2 }}>
            <Stack spacing={2} justifyContent="space-between" alignItems="center" direction="row">
              <Stack direction="column">
                <Typography variant="subtitle2">{t('profile.exporter.labels.cover_page')}</Typography>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  {t('profile.exporter.labels.cover_page_desc')}
                </Typography>
              </Stack>
              <Switch
                checked={coverPageEnabled}
                onChange={(e) => setCoverPageEnabled(e.target.checked)}
                color="primary"
              />
            </Stack>
          </Box>

          {
            freeTextFields.length > 0 && (
              <Grid container spacing={2} sx={{ px: 2 }}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">{t('profile.exporter.labels.cover_field_mapping')}</Typography>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {t('profile.exporter.labels.cover_field_mapping_desc')}
                  </Typography>
                </Grid>
                {
                  freeTextFields.map((field) => (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label={field.label}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          disabled={!coverPageEnabled || exportLoading}
                          onChange={(e) => updateFieldValue(field.label, e.target.value)}
                          label={t('profile.exporter.labels.field_value')}
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  ))
                }

              </Grid>
            )
          }

          <Grid item xs={12} />
        </Stack>
      </DialogContent >

      <DialogActions>
        <LoadingButton
          loading={isLoadingExportSettings || exportLoading}
          variant="contained"
          disabled={isLoadingExportSettings || !profileId}
          onClick={handleProfileExport}
        >
          {t('common.export')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  );
}
