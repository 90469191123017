import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm, Resolver } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import {
  Paper,
  Stack,
  Dialog,
  MenuItem,
  Skeleton,
  useTheme,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  FormHelperText,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useGetTypeDefinitionsQuery } from 'src/services/system/system.service';
import { useSetCandidateProfileMutation } from 'src/services/candidates/candidates.service';
import {
  EducationLevel,
  CandidateProfile,
  IEducationHistory,
} from 'src/services/candidates/candidates.types';

import Iconify from 'src/components/iconify';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';

type Props = {
  profile?: CandidateProfile;
  isLoading: boolean;
  mode?: 'edit' | 'view';
};

type EducationFormValues = {
  institution_name: string;
  certification_name: string;
  level: string;
  start_year: Date | null;
  end_year: Date | null;
};

export default function ProfileEducationHistory({ profile, isLoading, mode = 'edit' }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [updateProfile, { isLoading: isUpdating }] = useSetCandidateProfileMutation();

  const showEducationEditorModal = useBoolean();

  const [history, setHistory] = useState<IEducationHistory[]>([]);

  const [selectedHistoryItem, setSelectedHistoryItem] = useState<IEducationHistory | undefined>(
    undefined
  );

  const onShowAddHistoryModal = (id?: string | null) => {
    showEducationEditorModal.onTrue();

    if (id) setSelectedHistoryItem(history.find((item) => item.id === id));
  };

  const { t } = useTranslate();

  const theme = useTheme();

  const setProfileData = () => {
    if (!profile || isLoading) return;

    const sortedHistory: IEducationHistory[] =
      profile.education_history
        ?.slice()
        // Sort by start year descending
        .sort((a: IEducationHistory, b: IEducationHistory) => b.start_year - a.start_year) || [];

    setHistory(sortedHistory);
  };

  useEffect(() => {
    setProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, profile]);

  const onSaveExperiences = async (updatedExperiences: IEducationHistory[]) => {
    try {
      await updateProfile({
        id: profile?.id as string,
        data: {
          education_history: updatedExperiences,
        },
      }).unwrap();

      enqueueSnackbar(t('profile.api.education_history.success'), { variant: 'success' });

      onCloseEditor();
    } catch (e) {
      console.error(e);

      enqueueSnackbar(t('profile.api.education_history.error'), { variant: 'error' });
    }
  };

  const onUpdateHistory = (experience: IEducationHistory) => {
    const existingExperienceIndex = history.findIndex((exp) => exp.id === experience.id);
    let updatedHistory = [];

    if (existingExperienceIndex !== -1) {
      updatedHistory = [...history];
      updatedHistory[existingExperienceIndex] = experience;
    } else {
      updatedHistory = [experience, ...history];
    }

    onSaveExperiences(updatedHistory);
  };

  const onRemoveHistory = (experienceId: string) => {
    const updatedExperiences = history.filter((exp) => exp.id !== experienceId);

    if (updatedExperiences) onSaveExperiences(updatedExperiences ?? []);
  };

  const onCloseEditor = () => {
    setSelectedHistoryItem(undefined);
    showEducationEditorModal.onFalse();
  };

  const renderForm = () => (
    <>
      {
        isLoading ? (
          <Grid
            xs={12}
          >
            <Skeleton variant="rectangular" height={200} />
          </Grid>
        ) : (
          <Stack direction="column">
            <Paper
              variant="outlined"
              sx={{
                paddingX: 2,
                paddingY: 3,
                marginBottom: 2,
                backgroundColor: theme.palette.background.neutral,
              }}
            >
              <Stack spacing={2} display="flex" justifyContent="flex-start">
                {history?.length ? (
                  history.map((education, index) => (
                    <Card
                      key={index}
                      id={`${education.end_year}-${education.institution_name}`}
                      sx={{ backgroundColor: theme.palette.background.default }}
                    >
                      <CardHeader
                        sx={{
                          flexWrap: 'wrap'
                        }}
                        title={education.certification_name}
                        subheader={`${education.institution_name} ${education.start_year} ${education.end_year ? `- ${education.end_year}` : ''}`}
                        action={
                          mode === 'edit' && (
                            <Stack direction="row">
                              <IconButton
                                className="text-gray-500"
                                disabled={isUpdating}
                                onClick={() => onShowAddHistoryModal(education.id)}
                              >
                                <Iconify icon="solar:pen-bold" />
                              </IconButton>
                              <IconButton
                                className="text-gray-500 delete-experience-button"
                                disabled={isUpdating}
                                onClick={() => onRemoveHistory(education.id as string)}
                              >
                                <Iconify icon="mdi:delete" />
                              </IconButton>
                            </Stack>
                          )
                        }
                      />
                      <CardContent>
                        <Grid container wrap="wrap">
                          <Grid xs={12} md={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                              {education.institution_name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography textAlign="center" variant='subtitle2' className="text-gray-400">
                    {t('profile.sections.education_history.no_education')}
                  </Typography>
                )}
              </Stack>
            </Paper>
          </Stack>
        )
      }
      {
        mode === 'edit' && (
          <CardActions sx={{ px: 0, pt: 2, justifyContent: 'flex-end' }}>
            <Button
              className="text-blue-500"
              onClick={() => onShowAddHistoryModal()}
              startIcon={<Iconify icon="solar:pen-bold" width={16} />}
              disabled={isLoading || isUpdating}
            >
              {t('profile.sections.education_history.add_experience')}
            </Button>
          </CardActions>
        )
      }
    </>
  );

  return (
    <Card>
      <CardHeader
        title={isLoading ? <Skeleton width={200} /> : t('profile.sections.education_history.title')}
      />
      <CardContent>{renderForm()}</CardContent>
      <EducationHistoryEditModal
        open={showEducationEditorModal.value}
        onClose={onCloseEditor}
        experience={selectedHistoryItem}
        addExperience={onUpdateHistory}
      />
    </Card>
  );
}

type ModalProps = {
  open: boolean;
  onClose: VoidFunction;
  experience?: IEducationHistory;
  addExperience: (experience: IEducationHistory) => void;
};

export function EducationHistoryEditModal({ open, onClose, experience, addExperience }: ModalProps) {
  const { t } = useTranslate();

  const theme = useTheme();

  const { currentData: typeDefintions } = useGetTypeDefinitionsQuery();

  const defaultValues: EducationFormValues = {
    institution_name: '',
    certification_name: '',
    level: '',
    start_year: null,
    end_year: null,
  };

  const validationSchema = Yup.object().shape({
    institution_name: Yup.string().required(t('validation.required')),
    certification_name: Yup.string().required(t('validation.required')),
    level: Yup.string().required(t('validation.required')),
    start_year: Yup.date().required(t('validation.required')),
    end_year: Yup.date().nullable(),
  });

  const formMethods = useForm<EducationFormValues>({
    resolver: yupResolver(validationSchema) as Resolver<EducationFormValues>,
    defaultValues,
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { isDirty, errors },
    watch,
    reset,
    setValue,
  } = formMethods;

  const setFormValues = useCallback(() => {
    if (!experience) return;

    setValue('institution_name', experience.institution_name);
    setValue('certification_name', experience.certification_name);
    setValue('level', experience.level);
    setValue('start_year', new Date(experience.start_year, 0));
    setValue('end_year', experience?.end_year ? new Date(experience?.end_year, 0) : null);
  }, [experience, setValue]);

  useEffect(() => {
    if (!experience) return;

    setFormValues();
  }, [experience, setFormValues]);

  const onSubmit = handleSubmit((data) => {
    addExperience({
      id: experience?.id || undefined,
      institution_name: data.institution_name,
      certification_name: data.certification_name,
      level: data.level as EducationLevel,
      start_year: (data.start_year as Date).getFullYear(),
      end_year: data.end_year ? (data.end_year as Date).getFullYear() : null,
    });
  });

  const startDate = watch('start_year');
  const endDate = watch('end_year');

  const onCloseDialog = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onCloseDialog}>
      <DialogTitle>
        {t(`profile.sections.education_history.modal.title_${experience ? 'edit' : 'add'}`)}
      </DialogTitle>
      <DialogContent sx={{ pt: 4, pb: 2 }}>
        <FormProvider methods={formMethods} onSubmit={onSubmit}>
          <Grid container spacing={2} sx={{ py: 2 }}>
            <Grid xs={12}>
              <RHFTextField
                name="certification_name"
                label={t('profile.sections.education_history.modal.form.certification_name.label')}
                placeholder={t(
                  'profile.sections.education_history.modal.form.certification_name.placeholder'
                )}
                fullWidth
                sx={{ backgroundColor: theme.palette.background.paper }}
                ref={null}
              />
            </Grid>
            <Grid xs={6}>
              <RHFTextField
                name="institution_name"
                label={t('profile.sections.education_history.modal.form.institution_name.label')}
                placeholder={t(
                  'profile.sections.education_history.modal.form.institution_name.placeholder'
                )}
                fullWidth
                sx={{ backgroundColor: theme.palette.background.paper }}
                ref={null}
              />
            </Grid>
            <Grid xs={6}>
              <RHFSelect
                name="level"
                label={t('profile.sections.education_history.modal.form.certification_name.label')}
                placeholder={t(
                  'profile.sections.education_history.modal.form.certification_name.placeholder'
                )}
                fullWidth
                sx={{ backgroundColor: theme.palette.background.paper }}
                ref={null}
              >
                {typeDefintions?.EducationLevel?.map((level) => (
                  <MenuItem key={level} value={level}>
                    {t(`enums.education_level.${level}`)}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid xs={6}>
              <Stack direction="row" spacing={1}>
                <MobileDatePicker
                  name="start_year"
                  views={['year']}
                  label={t('common.form_labels.start_date')}
                  minDate={new Date('1970-01-01')}
                  maxDate={new Date()}
                  value={startDate ?? null}
                  onChange={(value: Date | null) => {
                    setValue('start_year', value, { shouldDirty: true, shouldValidate: true });
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      margin: 'normal',
                    },
                  }}
                  sx={{ mt: 0 }}
                />
              </Stack>
            </Grid>
            <Grid xs={6}>
              <Stack direction="column" spacing={1}>
                <MobileDatePicker
                  name="end"
                  views={['year']}
                  label={t('common.form_labels.end_date')}
                  minDate={new Date('1970-01-01')}
                  maxDate={new Date()}
                  value={endDate ?? null}
                  onChange={(value: Date | null) => {
                    setValue('end_year', value, { shouldDirty: true, shouldValidate: true });
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      margin: 'normal',
                      style: {
                        borderColor: !!errors.end_year ? theme.palette.error.main : 'inherit',
                      },
                    },
                  }}
                  sx={{ mt: 0, mb: 0 }}
                />
                <FormHelperText sx={{ mt: 0, pl: 1 }} error={!!errors.end_year}>
                  {errors.end_year?.message}
                </FormHelperText>
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button variant="text" onClick={onCloseDialog}>
                  {t('common.cancel')}
                </Button>
                <Button
                  variant="contained"
                  disabled={!isDirty}
                  endIcon={
                    <Iconify icon={experience ? 'solar:pen-bold' : 'material-symbols:add'} />
                  }
                  type="submit"
                >
                  {experience ? t('common.save') : t('common.add')}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
