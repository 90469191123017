import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useState, useEffect, useCallback } from 'react';

import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Grid,
  Stack,
  useTheme,
  CardHeader,
  Typography,
  CardContent,
  useMediaQuery,
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useGetOrganizationBrandSettingsQuery, useUpdateOrganizationBrandSettingsMutation } from 'src/services/organisation/organisation.service';

import Iconify from 'src/components/iconify';
import RHFTextField from 'src/components/hook-form/rhf-text-field';
import ImageCropper from 'src/components/image-cropper/image-cropper';

interface BrandSettingsFormData {
  primary_color: string;
  secondary_color: string;
  brand_logo_id?: string;
}

export default function BrandSettingsCard() {

  const { t } = useTranslate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { organization } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [photoURL, setPhotoURL] = useState<string>('');

  const { currentData: settingsData, isLoading: isLoadingSettings } = useGetOrganizationBrandSettingsQuery(
    organization.id
  );

  const [updateBrandSettings, { isLoading: isUpdating }] = useUpdateOrganizationBrandSettingsMutation();

  const OrgBrandSettingsSchema = Yup.object().shape({
    primary_color: Yup.string()
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, t('validation.invalid_color'))
      .required(t('validation.required')),
    secondary_color: Yup.string()
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, t('validation.invalid_color'))
      .required(t('validation.required')),
    brand_logo_id: Yup.string()
  });

  const methods = useForm({
    resolver: yupResolver(OrgBrandSettingsSchema),
    defaultValues: {
      primary_color: '',
      secondary_color: '',
      brand_logo_id: '',
    },
  });

  useEffect(() => {
    if (settingsData) {
      methods.reset({
        primary_color: settingsData.primary_color,
        secondary_color: settingsData.secondary_color,
        brand_logo_id: settingsData.brand_logo?.id as string,
      });

      setPhotoURL(settingsData?.brand_logo?.public_path);
    }
  }, [settingsData, methods]);

  const { handleSubmit, formState: { errors } } = methods;

  const onSubmit = handleSubmit(async (data: BrandSettingsFormData) => {
    try {

      await updateBrandSettings({
        organizationId: organization?.id,
        primary_color: data.primary_color,
        secondary_color: data.secondary_color,
        brand_logo_id: data.brand_logo_id as string
      }).unwrap();

      enqueueSnackbar(t('admin-organization.settings.brand_settings.api.update.success'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('admin-organization.settings.brand_settings.api.update.default_error'), { variant: 'error' });
    }
  });

  const onEditPhoto = useCallback((fileId: string) => {
    methods.setValue('brand_logo_id', fileId);
    onSubmit();
  }, [methods, onSubmit]);

  if (!organization) {
    return (
      <Box sx={{ width: '100%' }}>
        <Card >
          <CardContent>
            <Typography>{t('common.loading')}</Typography>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Card
        sx={{
          width: '100%',
          position: 'relative',
          boxShadow: 0
        }}
      >
        <CardHeader
          avatar={
            <Iconify
              icon="clarity:network-settings-solid"
              sx={{
                width: 30,
                height: 30,
                color: theme.palette.primary.main,
              }}
            />
          }
          title={
            <Typography variant="h6" component="div">
              {t('admin-organization.settings.brand_settings.title')}
            </Typography>
          }
        />

        <CardContent>
          <FormProvider {...methods} >
            <Stack spacing={3} sx={{ px: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ mb: 3 }}>
                  <ImageCropper
                    onSubmit={onEditPhoto}
                    existingUrl={photoURL}
                    showGrid
                    warningMessage={t('components.image_cropper.warning_message')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <RHFTextField
                    name="primary_color"
                    label={t('admin-organization.settings.brand_settings.labels.primary_color')}
                    type="color"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RHFTextField
                    name="secondary_color"
                    label={t('admin-organization.settings.brand_settings.labels.secondary_color')}
                    type="color"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  onClick={onSubmit}
                  disabled={isUpdating || !methods.formState.isValid}
                  loading={isUpdating}
                >
                  {t('common.update')}
                </LoadingButton>
              </Box>
            </Stack>
          </FormProvider>
        </CardContent>
      </Card>
    </Box>
  );
}