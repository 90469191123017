
import { Tooltip, useTheme, useMediaQuery } from '@mui/material';

import { useTranslate } from 'src/locales';
import { JobVerificationStatus } from 'src/services/jobs/jobs.types';

import Iconify from 'src/components/iconify';

export function JobVerificationStatusIcon({ status }: { status: JobVerificationStatus }) {

  const { t } = useTranslate();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderIcon = () => {
    if (status === JobVerificationStatus.UNVERIFIED) {
      return (
        <Tooltip title={t('jobs.verification_status_tooltips.unverified')}>
          <Iconify icon="octicon:unverified-16" color="gray" width={isMobile ? 16 : 20} height={isMobile ? 16 : 20} />
        </Tooltip>
      );
    }

    if (status === JobVerificationStatus.VERIFIED) {
      return (
        <Tooltip title={t('jobs.verification_status_tooltips.verified')}>
          <Iconify icon="lucide:verified" color="green" width={isMobile ? 16 : 20} height={isMobile ? 16 : 20} />
        </Tooltip>
      );
    }

    if (status === JobVerificationStatus.PENDING) {
      return (
        <Tooltip title={t('jobs.verification_status_tooltips.pending')}>
          <Iconify icon="mdi:account-pending" color="darkorange" width={isMobile ? 16 : 20} height={isMobile ? 16 : 20} />
        </Tooltip>
      );
    }

    return null;
  }

  return (
    <>
      {renderIcon()}
    </>
  )
}
