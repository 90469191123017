import Tooltip from '@mui/material/Tooltip';

import { useTranslate } from 'src/locales';
import { RoleStatus } from 'src/services/jobs/jobs.types';

import Label, { LabelProps } from 'src/components/label';

type Props = {
  status: RoleStatus;
} & LabelProps;

export default function RoleStatusLabel({ status, sx, ...other }: Props) {
  const { t } = useTranslate();

  const getLabelColor = (jobStatus: RoleStatus) => {
    switch (status) {
      case RoleStatus.ON_HOLD:
        return 'warning';
      case RoleStatus.LIVE:
        return 'success';
      case RoleStatus.CLOSED:
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <Tooltip title={t(`enums.role_status.${status}_tooltip`)}>
      <Label variant="soft" color={getLabelColor(status)}>
        {t(`enums.role_status.${status}`)}
      </Label>
    </Tooltip>
  );
}
