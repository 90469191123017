import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, forwardRef, useImperativeHandle } from 'react';

import {
  Box,
  Card,
  Stack,
  Divider,
  Typography
} from '@mui/material';

import { useTranslate } from 'src/locales';
import {
  IProfileLink,
  IWorkExperience,
  IEducationHistory,
  IPreferredLocation,
  ISalaryExpectation,
  CandidateProfileSectionType
} from 'src/services/candidates/candidates.types';

import FormProvider, { RHFEditor } from 'src/components/hook-form';

import LinksEditor from 'src/sections/jobs/applications/modals/resource-requests/profile-editors/links-editor';
import SkillsEditor from 'src/sections/jobs/applications/modals/resource-requests/profile-editors/skills-editor';
import JobTypeEditor from 'src/sections/jobs/applications/modals/resource-requests/profile-editors/job-type-editor';
import WorkStylesEditor from 'src/sections/jobs/applications/modals/resource-requests/profile-editors/work-style-editor';
import WorkRightsEditor from 'src/sections/jobs/applications/modals/resource-requests/profile-editors/work-rights-editor';
import NoticePeriodEditor from 'src/sections/jobs/applications/modals/resource-requests/profile-editors/notice-period-editor';
import EducationLevelEditor from 'src/sections/jobs/applications/modals/resource-requests/profile-editors/education-level-editor';
import WorkExperienceEditor from 'src/sections/jobs/applications/modals/resource-requests/profile-editors/work-experience-editor';
import EducationHistoryEditor from 'src/sections/jobs/applications/modals/resource-requests/profile-editors/education-history-editor';
import SalaryExpectationsEditor from 'src/sections/jobs/applications/modals/resource-requests/profile-editors/salary-expectation-editor';
import WorkplaceLocationEditor from 'src/sections/jobs/applications/modals/resource-requests/profile-editors/workplace-locations-editor';

export type ProfileCurrentInformation = {
  [CandidateProfileSectionType.EXECUTIVE_SUMMARY]?: string;
  [CandidateProfileSectionType.JOB_TYPES]?: string[];
  [CandidateProfileSectionType.SALARY_EXPECTATIONS]?: ISalaryExpectation[];
  [CandidateProfileSectionType.WORK_LOCATIONS]?: IPreferredLocation[];
  [CandidateProfileSectionType.WORK_RIGHTS]?: string;
  [CandidateProfileSectionType.WORK_STYLES]?: string[];
  [CandidateProfileSectionType.EDUCATION_LEVEL]?: string;
  [CandidateProfileSectionType.WORK_EXPERIENCES]?: IWorkExperience[];
  [CandidateProfileSectionType.NOTICE_PERIOD]?: string;
  [CandidateProfileSectionType.SKILLS]?: string[];
  [CandidateProfileSectionType.EDUCATION_HISTORY]?: IEducationHistory[];
  [CandidateProfileSectionType.LINKS]?: IProfileLink[];
}

type SteppedProfileSelectorProps = {
  section: CandidateProfileSectionType;
  currentInformation?: ProfileCurrentInformation;
};

export interface SteppedProfileSelectorHandle {
  validate: () => Promise<boolean>;
  getData: () => any;
}

const SteppedProfileSelector = forwardRef<SteppedProfileSelectorHandle, SteppedProfileSelectorProps>(({
  section,
  currentInformation
}, ref) => {

  const { t } = useTranslate();

  const validationSchema = Yup.object().shape({
    executive_summary: Yup.string().required(t('validation.required')),
    education_level: Yup.string().required(t('validation.required')),
    work_rights: Yup.string().required(t('validation.required')),
    notice_period: Yup.string().required(t('validation.required')),
    skills: Yup.array().of(Yup.string()).min(1, t('validation.array_min', { length: 1 })),
    job_types: Yup.array().of(Yup.string().defined()).min(1, t('validation.array_min', { length: 1 })),
    work_experiences: Yup.array().of(
      Yup.object<IWorkExperience>().shape({
        company_name: Yup.string().required(t('validation.required')),
        position_title: Yup.string().max(60, t('validation.max_length', { length: 60 })).required(t('validation.required')),
        start: Yup.object().shape({
          month: Yup.number().required(t('validation.required')),
          year: Yup.number().required(t('validation.required'))
        }).defined(),
        end: Yup.object().shape({
          month: Yup.number().required(t('validation.required')),
          year: Yup.number().required(t('validation.required'))
        }).notRequired(),
        role_summary: Yup.string().required(t('validation.required')),
      })
    ).min(1, t('validation.array_min', { length: 1 })),
    salary_expectations: Yup.array().of(
      Yup.object().shape({
        min_amount: Yup.number().defined(),
        max_amount: Yup.number().defined(),
        period: Yup.string().defined(),
      })
    ).min(1, t('validation.array_min', { length: 1 })),
    work_locations: Yup.array().of(
      Yup.object().shape({
        place_id: Yup.string().defined(),
        address: Yup.string().defined(),
        latitude: Yup.number().defined(),
        longitude: Yup.number().defined()
      })
    ).min(1, t('validation.array_min', { length: 1 })),
    working_styles: Yup.array().of(Yup.string().defined()).min(1, t('validation.array_min', { length: 1 })),
    education_history: Yup.array().of(
      Yup.object<IEducationHistory>().shape({
        institution_name: Yup.string().required(t('validation.required')),
        certification_name: Yup.string().required(t('validation.required')),
        level: Yup.string().required(t('validation.required')),
        start_year: Yup.number().required(t('validation.required')),
        end_year: Yup.number().nullable()
      })
    ).min(1, t('validation.array_min', { length: 1 })),
    links: Yup.array().of(
      Yup.object<IProfileLink>().shape({
        type: Yup.string().required(t('validation.required')),
        url: Yup.string().url(t('validation.url'))
      })
    ).min(1, t('validation.array_min', { length: 1 })),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      executive_summary: '',
      education_level: '',
      work_rights: '',
      notice_period: '',
      skills: [],
      job_types: [],
      work_experiences: [],
      salary_expectations: [],
      work_locations: [],
      working_styles: [],
      education_history: [],
      links: [],
    },
    mode: 'all',
  });

  const {
    formState: { errors },
    trigger,
    getValues,
    reset
  } = methods;

  console.log(getValues());

  // Reset form values when currentInformation changes
  useEffect(() => {
    if (currentInformation) {
      reset({
        executive_summary: currentInformation?.[CandidateProfileSectionType.EXECUTIVE_SUMMARY] ?? '',
        education_level: currentInformation?.[CandidateProfileSectionType.EDUCATION_LEVEL] ?? '',
        work_rights: currentInformation?.[CandidateProfileSectionType.WORK_RIGHTS] ?? '',
        notice_period: currentInformation?.[CandidateProfileSectionType.NOTICE_PERIOD] ?? '',
        skills: currentInformation?.[CandidateProfileSectionType.SKILLS] ?? [],
        job_types: currentInformation?.[CandidateProfileSectionType.JOB_TYPES] ?? [],
        work_experiences: currentInformation?.[CandidateProfileSectionType.WORK_EXPERIENCES] ?? [],
        salary_expectations: currentInformation?.[CandidateProfileSectionType.SALARY_EXPECTATIONS] ?? [],
        work_locations: currentInformation?.[CandidateProfileSectionType.WORK_LOCATIONS] ?? [],
        working_styles: currentInformation?.[CandidateProfileSectionType.WORK_STYLES] ?? [],
        education_history: currentInformation?.[CandidateProfileSectionType.EDUCATION_HISTORY] ?? [],
        links: currentInformation?.[CandidateProfileSectionType.LINKS] ?? [],
      });
    }
  }, [currentInformation, reset]);

  const renderSectionContent = () => {

    if (!currentInformation) {
      return <Typography>{t('common.not_supported')}</Typography>;
    }

    switch (section) {
      case CandidateProfileSectionType.EXECUTIVE_SUMMARY:
        return (
          <Box>
            <RHFEditor name="executive_summary" />
          </Box>
        );

      case CandidateProfileSectionType.SALARY_EXPECTATIONS:
        return (
          <Box sx={{ p: 2 }}>
            <SalaryExpectationsEditor
              current={currentInformation[section]}
            />
          </Box>
        );

      case CandidateProfileSectionType.WORK_LOCATIONS:
        return (
          <Box sx={{ p: 2, pt: 4 }}>
            <WorkplaceLocationEditor />
          </Box>
        );

      case CandidateProfileSectionType.WORK_STYLES:
        return (
          <Box sx={{ p: 2, pt: 4 }}>
            <WorkStylesEditor />
          </Box>
        );

      case CandidateProfileSectionType.JOB_TYPES:
        return (
          <Box sx={{ p: 2, pt: 4 }}>
            <JobTypeEditor />
          </Box>
        );
      case CandidateProfileSectionType.EDUCATION_LEVEL:
        return (
          <Box sx={{ p: 2, pt: 4 }}>
            <EducationLevelEditor
              current={currentInformation[section]}
            />
          </Box>
        );
      case CandidateProfileSectionType.WORK_RIGHTS:
        return (
          <Box sx={{ p: 2, pt: 4 }}>
            <WorkRightsEditor />
          </Box>
        );
      case CandidateProfileSectionType.NOTICE_PERIOD:
        return (
          <Box sx={{ p: 2, pt: 4 }}>
            <NoticePeriodEditor />
          </Box>
        );

      case CandidateProfileSectionType.WORK_EXPERIENCES:
        return (
          <Box sx={{ p: 2, pt: 4 }}>
            <WorkExperienceEditor />
          </Box>
        );

      case CandidateProfileSectionType.EDUCATION_HISTORY:
        return (
          <Box sx={{ p: 2, pt: 4 }}>
            <EducationHistoryEditor />
          </Box>
        );

      case CandidateProfileSectionType.LINKS:
        return (
          <Box sx={{ p: 2, pt: 4 }}>
            <LinksEditor />
          </Box>
        );

      case CandidateProfileSectionType.SKILLS:
        return (
          <Box sx={{ p: 2, pt: 4 }}>
            <SkillsEditor />
          </Box>
        );

      default:
        return <Typography>{t('common.not_supported')}</Typography>;
    }
  };

  const renderError = () => errors[section as keyof ProfileCurrentInformation] && (
    <Typography color="error" variant="caption">
      {errors[section as keyof ProfileCurrentInformation]?.message}
    </Typography>
  );

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const isValidCheck = await trigger(section as keyof ProfileCurrentInformation);
      return isValidCheck;
    },
    getData: () => getValues()
  }));

  return (
    <Card variant="outlined">
      <Box sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">
              {t(`enums.candidate_profile_section.${section}`)}
            </Typography>
          </Stack>

          <Divider />

          <Box>

            <FormProvider methods={methods}>

              {renderSectionContent()}

              {renderError()}

            </FormProvider>
          </Box>
        </Stack>
      </Box>
    </Card>
  );
});

SteppedProfileSelector.displayName = 'SteppedProfileSelector';

export default SteppedProfileSelector;