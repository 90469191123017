import { useSearchParams } from 'react-router-dom';
import React, { useState, useEffect, SyntheticEvent } from 'react';

import { Stack } from '@mui/system';
import {
  Box,
  Tab,
  Card,
  Tabs,
  Divider
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';

import { useFeatures } from 'src/sections/billing/usage/hooks/use-features';
import BrandSettingsCard from 'src/sections/admin/organization/settings/components/brand-settings';
import BusinessSettingsCard from 'src/sections/admin/organization/settings/components/business-settings';

import { TenantType } from 'src/types/enums';
import { FeatureType } from 'src/types/subscription.types';

import JobBoardCard from '../components/job-board-card';
import SubTenantCard from '../components/sub-tenant-card';
import ExportSettingsCard from '../components/export-settings';
import WorkingHoursComponent from '../components/working-hour-selector';

interface TabPanelProps {
  children?: React.ReactNode;
  identifier: TabValue;
  value: TabValue;
}

function TabPanel(props: TabPanelProps) {

  const { children, value, identifier, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== identifier}
      id={`settings-tabpanel-${identifier}`}
      aria-labelledby={`settings-tab-${identifier}`}
      {...other}
    >
      {value === identifier && (
        <Box sx={{ py: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

// helper function for a11y props
function a11yProps(index: number) {
  return {
    id: `settings-tab-${index}`,
    'aria-controls': `settings-tabpanel-${index}`,
  };
}

enum TabValue {
  Business = 'business',
  Brand = 'brand',
  SubOrg = 'sub-org',
  JobBoard = 'job-board'
}

export default function OrganizationSettingsView() {
  const { t } = useTranslate();
  const tenantType = useOrgTenant();
  const { canAccess } = useFeatures();

  const [searchParams, setSearchParams] = useSearchParams();

  const [value, setValue] = useState<TabValue>(tenantType === TenantType.Recruiter ? TabValue.Business : TabValue.Brand);

  useEffect(() => {
    const tab = searchParams.get('tab') as TabValue;
    if (tab) {
      setValue(tab);
    }
  }, [searchParams]);

  const handleChange = (_event: SyntheticEvent, newValue: TabValue) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });
  };

  const isRecruiter = tenantType === TenantType.Recruiter;
  const canAccessSubOrg = canAccess(FeatureType.Sub_Organization_Enabled);
  const canAccessJobBoard = [TenantType.Recruiter, TenantType.Client].includes(tenantType);

  return (
    <Card sx={{ px: 2, pt: 1 }}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="organization settings tabs"
          >
            {isRecruiter && <Tab label={t('admin-organization.settings.tabs.business')} {...a11yProps(2)} value={TabValue.Business} />}
            <Tab label={t('admin-organization.settings.tabs.brand')} {...a11yProps(1)} value={TabValue.Brand} />
            {canAccessSubOrg && <Tab label={t('admin-organization.settings.tabs.sub_org')} {...a11yProps(3)} value={TabValue.SubOrg} />}
            {canAccessJobBoard && <Tab label={t('admin-organization.settings.tabs.job_board')} {...a11yProps(4)} value={TabValue.JobBoard} />}
          </Tabs>
        </Box>

        {/* business settings tab - only for recruiter */}
        {isRecruiter && (
          <TabPanel value={value} identifier={TabValue.Business}>
            <Stack spacing={2}>
              <BusinessSettingsCard />
              <Divider />
              <WorkingHoursComponent />
            </Stack>
          </TabPanel>
        )}

        <TabPanel value={value} identifier={TabValue.Brand}>
          <BrandSettingsCard />
          <Divider />
          <ExportSettingsCard />
        </TabPanel>

        {/* sub-organization tab - only if feature is enabled */}
        {canAccessSubOrg && (
          <TabPanel value={value} identifier={TabValue.SubOrg}>
            <SubTenantCard />
          </TabPanel>
        )}

        {/* job Board tab - only if feature is enabled  */}
        {canAccessJobBoard && (
          <TabPanel value={value} identifier={TabValue.JobBoard}>
            <JobBoardCard />
          </TabPanel>
        )}
      </Box>
    </Card>
  );
}