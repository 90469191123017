
export function convertRgbToHex(htmlContent: string) {
  return htmlContent.replace(
    /rgb\((\d+),\s*(\d+),\s*(\d+)\)/g,
    (_, r, g, b) => {
      const hex = `#${ 
        parseInt(r, 10).toString(16).padStart(2, '0') 
        }${parseInt(g, 10).toString(16).padStart(2, '0') 
        }${parseInt(b, 10).toString(16).padStart(2, '0')}`;
      return hex;
    }
  );
}

export function removeInlineStyles(htmlContent: string): string {
  if (!htmlContent) return htmlContent;

  // Create a DOMParser to safely parse the HTML
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  
  // Find all elements with style attributes
  const elementsWithStyle = doc.querySelectorAll('[style]');
  
  // Remove style attribute from each element
  elementsWithStyle.forEach((element) => {
    element.removeAttribute('style');
  });

  // Return the cleaned HTML content (from the body, since parseFromString creates a complete document)
  return doc.body.innerHTML;
}

export const decodeHTML = (input: string): string | null => {
  const e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
};
