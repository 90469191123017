import { debounce } from 'lodash';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useMemo, useState, ChangeEvent, useCallback } from 'react';

import { useTheme } from '@mui/material/styles';
import { Stack, useMediaQuery } from '@mui/system';
import { Card, Table, TableBody, TableContainer } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { localStorageGetItem } from 'src/utils/local-storage';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { CandidateAvailabilityStatus } from 'src/services/candidates/candidates.types';
import { toggleCandidateInviteModal } from 'src/store/slices/candidate/candidateSlice';
import { useGetOrganizationCandidatesQuery } from 'src/services/organisation/organisation.service';

import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import AddCandidateJobModal from 'src/sections/jobs/modals/add-candidate-job-modal';
import CandidatesTableToolbar from 'src/sections/candidates/table/candidate-table-toolbar';

import { TenantType } from 'src/types/enums';

import { CandidateTableRow } from './candidate-table-row';

const DEFAULT_PAGE: PageOptions = {
  page: 1,
  per_page: 10,
};

export default function CandidatesTable() {
  const table = useTable();

  const theme = useTheme();

  const dispatch = useAppDispatch();

  const addCandidateJobControl = useBoolean();

  const authContext = useAuthContext();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const tenant = useOrgTenant();

  const { t } = useTranslate();

  const denseHeight = table.dense ? 56 : 56 + 20;

  const isCandidate = tenant === TenantType.Candidate;

  const tableLabels = useMemo(
    () => [
      { id: 'name', label: t('candidates.table.name') },
      { id: 'email', label: t('candidates.table.email') },
      ...tenant === TenantType.Recruiter ? [{ id: 'availablity', label: t('candidates.table.availability') }] : [],
      { id: 'updated_at', label: t('candidates.table.updated_at') },
      { id: 'actions', label: '' }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCandidate, tenant]
  );

  const [pageOptions, setPageOptions] = useState<PageOptions>(DEFAULT_PAGE);
  const [filterQuery, setFilterQuery] = useState<string | undefined>();
  const [filterSkills, setFilterSkills] = useState<string | undefined>();
  const [filterAvailability, setFilterAvailability] = useState<CandidateAvailabilityStatus | undefined>();

  const [currentCandidate, setCurrentCandidate] = useState<string | undefined>();

  const { currentData: candidates, isLoading: isCandidatesLoading } = useGetOrganizationCandidatesQuery(
    {
      search_query: filterQuery,
      skills_query: filterSkills,
      availability_status: filterAvailability,
      page: pageOptions,
    },
    { skip: !authContext.organization?.id }
  );

  const onChangePage = useCallback((_event: any, pageNumber: number) => {
    setPageOptions((prev) => ({
      ...prev,
      page: pageNumber + 1,
    }));
  }, []);

  const onChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPageOptions((prev) => ({
        ...prev,
        per_page: +event.target.value,
      }));
    },
    []
  );

  const handleFilters = useMemo(
    () => debounce((filter, value: string | TenantType) => {
      if (filter === 'search_query') {
        setFilterQuery(value as string);
      }
      else if (filter === 'skills') {
        setFilterSkills(value as string);
      }
      else if (filter === 'availability_status') {
        setFilterAvailability(value as CandidateAvailabilityStatus);
      }
    }, 500),
    [setFilterQuery]
  );

  const clearFilters = useCallback(() => {
    setFilterQuery('');
    setFilterSkills('');
    setFilterAvailability(undefined);
  }, []);

  const notFound = !candidates?.count;

  const openAddCandidateJobModal = useCallback((candidateId: string) => {
    setCurrentCandidate(candidateId);
    addCandidateJobControl.onTrue();
  }, [addCandidateJobControl]);

  const handleProfileExport = useCallback(async (candidateId: string) => {

    const toastId = enqueueSnackbar(t('candidates.export.api.loading'), {
      variant: 'loading',
      persist: true
    });

    try {

      // get the token from local storage
      const token = localStorageGetItem('access_token', undefined);

      // use fetch instead of rtk (doesn't fully support file download)
      const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/my/candidates/${candidateId}/profile/export`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to download');
      }

      // get the blob from the response
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${candidateId}_profile.pdf`); // Or use candidate name if available

      document.body.appendChild(link);
      link.click();

      // cleanup
      link.remove();
      window.URL.revokeObjectURL(url);

      closeSnackbar(toastId);
      enqueueSnackbar(t('candidates.export.api.success'), { variant: 'success' });
    }
    catch (error) {
      closeSnackbar(toastId);
      enqueueSnackbar(t('candidates.export.api.default_error'), { variant: 'error' });
    }

  }, [t]);

  const closeAddCandidateJobModal = useCallback(() => {
    setCurrentCandidate(undefined);
    addCandidateJobControl.onFalse();
  }, [addCandidateJobControl]);

  const handleOpenInviteCandidatesModal = useCallback(() => {
    dispatch(toggleCandidateInviteModal({
      status: true
    }));
  }, [dispatch]);

  return (
    <>
      <Card sx={{ borderRadius: 0 }}>

        {
          isCandidatesLoading ? (
            <TableSkeleton />
          )
            :
            <>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <CandidatesTableToolbar
                  onFilters={handleFilters}
                  clearFilters={clearFilters}
                  onInviteCandidates={handleOpenInviteCandidatesModal}
                />
              </Stack>

              <TableContainer sx={{ overflow: 'unset' }}>
                <Scrollbar>
                  <Table size={table.dense ? 'small' : 'medium'}>

                    {
                      !isMobile && (
                        <TableHeadCustom headLabel={tableLabels} />
                      )
                    }

                    <TableBody>
                      {candidates?.results?.map((row) => <CandidateTableRow key={row.id} row={row} onAddToJob={openAddCandidateJobModal} onExportProfile={handleProfileExport} />)}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(table.page, table.rowsPerPage, candidates?.count ?? 0)}
                      />

                      <TableNoData notFound={notFound} />
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>

              <TablePaginationCustom
                count={candidates?.count ?? 0}
                page={pageOptions.page}
                rowsPerPage={pageOptions.per_page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                dense={table.dense}
                onChangeDense={table.onChangeDense}
              />
            </>
        }
      </Card>

      <AddCandidateJobModal open={addCandidateJobControl.value} onClose={closeAddCandidateJobModal} candidateId={currentCandidate as string} />


    </>
  );
}
