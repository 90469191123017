import { useMemo, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { Card, Table, CardProps, TableBody, useMediaQuery } from '@mui/material';

import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useGetCandidateJobsQuery, useGetCandidateApplicationsQuery } from 'src/services/candidates/candidates.service';

import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  TableNoData,
  TableSkeleton,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { TenantType } from 'src/types/enums';

import JobTableRow from './jobs-table-row';

interface Props extends CardProps {
  candidateId: string;
}

const DEFAULT_PAGE_OPTIONS: PageOptions = {
  page: 1,
  per_page: 25,
};

export default function CandidateJobsList({ candidateId, ...other }: Props) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const table = useTable();

  const tenant = useOrgTenant();

  const authContext = useAuthContext();

  const { t } = useTranslate();

  const [pageOptions, setPageOptions] = useState<PageOptions>({
    page: DEFAULT_PAGE_OPTIONS.page,
    per_page: DEFAULT_PAGE_OPTIONS.per_page,
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: candidateJobs, isLoading: candidateJobsLoading } = useGetCandidateJobsQuery(
    { candidateId, ...pageOptions },
    { skip: !candidateId }
  );

  const { data: candidateApplications, isLoading: candidateApplicationsLoading } = useGetCandidateApplicationsQuery(
    { candidateId, ...pageOptions },
    { skip: !candidateId }
  );

  const tableLabels = useMemo(
    () => [
      { id: 'title', label: t('jobs.table.title') },
      ...([TenantType.Recruiter].includes(tenant)
        ? [{ id: 'client_company_name', label: t('jobs.table.client_company_name') }]
        : []),
      { id: 'role_status', label: t('jobs.table.role_status') },
      {
        id: 'stage', label: t(`jobs.table.stage`),
      },
      {
        id: 'last_updated_at', label: t(`jobs.table.last_updated_at`),
      },
      { id: '' },
    ],
    [t, tenant]
  );

  return (
    <Card {...other} sx={{ pt: { xs: 2, sm: 'unset' } }}>
      {candidateApplicationsLoading ? (
        <TableSkeleton />
      ) : (
        <>
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'}>
              {!isMobile && <TableHeadCustom headLabel={tableLabels} />}

              <TableBody>
                {candidateApplications?.results.map((row) => <JobTableRow key={row.id} row={row} />)}
                {!candidateApplications?.count && <TableNoData notFound />}
              </TableBody>
            </Table>
          </Scrollbar>
          <TablePaginationCustom
            count={candidateApplications?.count || 0}
            page={pageOptions.page}
            rowsPerPage={pageOptions.per_page}
            onPageChange={(e, newPage) => setPageOptions({ ...pageOptions, page: newPage + 1 })}
            onRowsPerPageChange={(e) =>
              setPageOptions({ page: 1, per_page: parseInt(e.target.value, 10) })
            }
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </>
      )}
    </Card>
  );
}
