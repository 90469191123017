import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useEffect, useCallback } from 'react';

import { Stack } from '@mui/system';
import Card from '@mui/material/Card';
import { LoadingButton } from '@mui/lab';
import CardHeader from '@mui/material/CardHeader';
import { Paper, Tooltip, Skeleton, useTheme, IconButton, Typography, CardActions, CardContent } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { VisibilityRule } from 'src/services/jobs/jobs.types';
import { useSetCandidateProfileMutation } from 'src/services/candidates/candidates.service';
import { generateCandidateProfileErrorMessage } from 'src/services/candidates/candidates.utils';
import {
  CandidateProfile,
  CandidateProfileSectionType,
} from 'src/services/candidates/candidates.types';

import Iconify from 'src/components/iconify';
import Markdown from 'src/components/markdown';
import RHFEditor from 'src/components/hook-form/rhf-editor';
import FormProvider from 'src/components/hook-form/form-provider';
import ProfileExporter from 'src/components/profile-exporter/profile-exporter';
import ProfileSummaryGenerator from 'src/components/generators/profile-summary-generator';

import { TenantType } from 'src/types/enums';

type Props = {
  profile?: CandidateProfile;
  isLoading: boolean;
  mode?: 'edit' | 'view';
};

export default function ProfileBio({ profile, isLoading, mode = 'edit' }: Props) {
  const toggleEditor = useBoolean();

  const { t } = useTranslate();

  const theme = useTheme();

  const tenant = useOrgTenant();

  const { enqueueSnackbar } = useSnackbar();

  const profileExporterControl = useBoolean();

  const [updateProfile] = useSetCandidateProfileMutation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const showEditor = useMemo(
    () => (toggleEditor.value || (!profile?.summary?.content && !isLoading)) && mode === 'edit',
    [isLoading, profile?.summary?.content, toggleEditor.value, mode]
  );

  const defaultValues = {
    summary: profile?.summary?.content ?? '',
    visibility: true,
  };

  const EditBioSchema = Yup.object().shape({
    summary: Yup.string().required(t('validation.required')),

    visibility: Yup.boolean(),
  });

  const summaryFormMethods = useForm({
    resolver: yupResolver(EditBioSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, isDirty },
  } = summaryFormMethods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateProfile({
        id: profile?.id as string,
        data: {
          summary: {
            content: data.summary,
          },
          sections: [
            {
              type: CandidateProfileSectionType.EXECUTIVE_SUMMARY,
              visibility_rule: VisibilityRule.PUBLIC,
            },
          ],
        },
      }).unwrap();

      reset();
    } catch (e) {
      console.log(e);

      enqueueSnackbar(generateCandidateProfileErrorMessage(e), { variant: 'error' });
    }
  });

  useEffect(() => {
    if (!isLoading) {
      reset(
        {
          summary: profile?.summary?.content ?? '',
        },
        { keepDirty: false }
      );
    }
  }, [isLoading, profile?.summary?.content, reset]);

  const handleCopyToClipboard = (text: string) => {
    if (!text) return;

    navigator.clipboard.writeText(text ?? "" as string);
    enqueueSnackbar(t('common.copied_to_clipboard'), { variant: 'success' });
  }

  const handleProfileExport = useCallback(async () => {
    profileExporterControl.onTrue();
  }, [profileExporterControl]);

  const closeProfileExport = useCallback(() => {
    profileExporterControl.onFalse();
  }, [profileExporterControl]);

  const renderForm = () => (
    <>
      {isLoading ? (
        <Skeleton variant="rectangular" height={100} />
      ) : (
        <FormProvider methods={summaryFormMethods} onSubmit={onSubmit}>
          {showEditor ? (
            <RHFEditor name="summary" id="summary_editor" />
          ) : (
            <Paper
              variant="outlined"
              id="summary_display"
              sx={{ p: 2, mb: 2, backgroundColor: theme.palette.background.neutral }}
              onClick={toggleEditor.onTrue}
            >
              <Markdown
                children={
                  !_.isEmpty(profile?.summary?.content)
                    ? profile?.summary?.content
                    : 'No summary added'
                }
              />
            </Paper>
          )}

          {mode === 'edit' && (
            <CardActions sx={{ px: 0, pt: 2, justifyContent: 'flex-end' }}>
              <LoadingButton
                variant="contained"
                color="success"
                type="submit"
                loading={isSubmitting}
                disabled={!isValid || !showEditor || !isDirty}
              >
                {t('common.save')}
              </LoadingButton>
            </CardActions>
          )}
        </FormProvider>
      )}
    </>
  );

  const shouldShowActions = useMemo(() => [TenantType.Client, TenantType.Recruiter].includes(tenant), [tenant]);

  const handleGenerateSummary = useCallback(async (summary: string) => {
    try {
      await updateProfile({
        id: profile?.id as string,
        data: {
          summary: {
            content: summary,
          },
          sections: [
            {
              type: CandidateProfileSectionType.EXECUTIVE_SUMMARY,
              visibility_rule: VisibilityRule.PUBLIC,
            },
          ],
        },
      }).unwrap();

      reset();
    } catch (e) {
      console.log(e);

      enqueueSnackbar(generateCandidateProfileErrorMessage(e), { variant: 'error' });
    }

  }, [enqueueSnackbar, profile?.id, reset, updateProfile]);

  return (
    <Card>
      <CardHeader
        title={
          isLoading ?
            <Skeleton width={200} />
            :
            <Stack direction="row" justifyContent="space-between" alignContent="center">
              <Typography variant='h6' sx={{ lineHeight: '40px' }}>
                {t('profile.sections.summary.title')}
              </Typography>
              <Stack direction="row" alignItems="center">
                {
                  shouldShowActions && (
                    <>

                      {
                        ([TenantType.Recruiter, TenantType.Client].includes(tenant) && mode === "edit") && (
                          <ProfileSummaryGenerator profileId={profile?.id as string} onUseGeneration={handleGenerateSummary} />
                        )
                      }

                      {
                        profile?.email && (
                          <Tooltip title={t('candidates.detail.actions.copy_email')}>
                            <IconButton onClick={() => handleCopyToClipboard(profile?.email as string)}>
                              <Iconify icon="mdi:email" />
                            </IconButton>
                          </Tooltip>
                        )

                      }
                      {
                        profile?.phone && (
                          <Tooltip title={t('candidates.detail.actions.copy_phone')}>
                            <IconButton onClick={() => handleCopyToClipboard(profile?.phone as string)}>
                              <Iconify icon="mdi:phone" />
                            </IconButton>
                          </Tooltip>
                        )
                      }

                      <Tooltip title={t('candidates.detail.actions.export_profile')}>
                        <IconButton onClick={handleProfileExport}>
                          <Iconify icon="bx:export" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )
                }

              </Stack>
            </Stack>
        }
        subheader={
          isLoading ? (
            <Skeleton width={300} />
          ) : (
            tenant === TenantType.Candidate && t('profile.sections.summary.placeholder')
          )
        }
      />
      <CardContent>{renderForm()}</CardContent>

      <ProfileExporter profileId={profile?.id as string} open={profileExporterControl.value} onClose={closeProfileExport} />
      
    </Card>
  );
}
