import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { Draggable, Droppable, DragDropContext } from '@hello-pangea/dnd';
import { useMemo, useEffect, forwardRef, useImperativeHandle } from 'react';

import { Box, Stack } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Grid, Paper, Button, MenuItem, IconButton, Typography } from "@mui/material";

import { useTranslate } from 'src/locales';
import { ExportFieldType, ExporterFieldMappingDto } from "src/services/organisation/organisation.types";

import Iconify from 'src/components/iconify';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';

type Props = {
  fieldMap: ExporterFieldMappingDto[];
}

export interface ExportFieldMapperHandle {
  validate: () => Promise<boolean>;
  getFieldMap: () => Promise<ExporterFieldMappingDto[]>;
  reset: () => void;
}

const ExportFieldMapper = forwardRef<ExportFieldMapperHandle, Props>(({ fieldMap = [] }, ref) => {

  const { t } = useTranslate();

  const FieldMapSchema = Yup.object().shape({
    fields: Yup.array()
      .of(
        Yup.object().shape({
          field_type: Yup.string().required(t('validation.required')),
          label: Yup.string().required(t('validation.required')),
        })
      )
      .required(t('validation.required'))
      .min(1, t('validation.array_min', { value: 1 }))
      .max(10, t('validation.array_max', { value: 10 })),
  });

  const defaultValues = {
    fields: (Array.isArray(fieldMap) && fieldMap.length > 0) ? fieldMap : [{ field_type: ExportFieldType.ASSIGNED_ACCOUNT_NAME, label: 'Assigned Consultant' }],
  };

  const methods = useForm({
    resolver: yupResolver(FieldMapSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    reset,
    control,
    trigger,
    getValues,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (Array.isArray(fieldMap) && fieldMap.length > 0) {
      reset({
        fields: fieldMap
      });
    }
  }, [fieldMap, reset]);

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'fields',
  });

  const fieldTypeOptions = useMemo(() => Object.values(ExportFieldType).map((type) => ({
    value: type,
    label: t(`admin-organization.settings.export_settings.field_mapper.fields.${type}`),
  })), [t]);

  useImperativeHandle(ref, () => ({
    validate: async () => trigger(undefined, { shouldFocus: true }),
    getFieldMap: async () => {
      const formData = getValues();
      return formData.fields as ExporterFieldMappingDto[];
    },
    reset: () => methods.reset()
  }));

  const isFieldLocked = (fieldType: ExportFieldType) => {
    if (ExportFieldType.FREE_TEXT === fieldType) return false;
    return fieldMap.map((field) => field.field_type).includes(fieldType);
  }

  const handleDragEnd = (result: any) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }

    // Move the item in the field array
    move(result.source.index, result.destination.index);
  };

  return (
    <FormProvider {...methods}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Stack direction="column" justifyContent="center" sx={{ mt: 2 }} gap={2}>
          {fields.length > 0 ? (
            <Droppable droppableId="fields-list">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {fields.map((field, index) => (
                    <Draggable key={field.id} draggableId={field.id} index={index}>
                      {(providedDrag) => (
                        <Paper
                          ref={providedDrag.innerRef}
                          {...providedDrag.draggableProps}
                          elevation={1}
                          sx={{ p: 2, mb: 2 }}
                        >
                          <Grid container spacing={2} sx={{ alignItems: 'start' }}>
                            <Grid item xs={1} sm={1}>
                              <IconButton
                                {...providedDrag.dragHandleProps}
                                sx={{ cursor: 'grab', mt: .5 }}
                              >
                                <DragIndicatorIcon />
                              </IconButton>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              
                              <RHFSelect
                                name={`fields.${index}.field_type`}
                                label={t('admin-organization.settings.export_settings.field_mapper.labels.field_type')}
                              >
                                {fieldTypeOptions?.map((option) => (
                                  <MenuItem key={option.value} value={option.value} disabled={isFieldLocked(option.value)}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </RHFSelect>
                            </Grid>

                            <Grid item xs={11} sm={5}>
                              <RHFTextField
                                name={`fields.${index}.label`}
                                label={t('admin-organization.settings.export_settings.field_mapper.labels.label')}
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                              <IconButton sx={{ mt: .5 }} onClick={() => remove(index)} color="error">
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="caption" sx={{ mb: 2 }}>
                {t('admin-organization.settings.export_settings.field_mapper.no_fields')}
              </Typography>
            </Box>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'start' }}>
            {
              errors?.fields?.root && (
                <Typography variant="caption" color="error">
                  {errors.fields.root.message}
                </Typography>
              )
            }
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'start' }}>
            <Button startIcon={<Iconify icon="gridicons:add-outline" />} variant="contained" color="primary" onClick={() => append({ field_type: '', label: '' })}>
              {t('admin-organization.settings.export_settings.field_mapper.add_field')}
            </Button>
          </Box>
        </Stack>
      </DragDropContext>
    </FormProvider>
  );
});

ExportFieldMapper.displayName = 'ExportFieldMapper';

export default ExportFieldMapper;