import { enqueueSnackbar } from 'notistack';
import { useRef, useState, useEffect, useCallback } from 'react';

import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Grid,
  Stack,
  useTheme,
  CardHeader,
  Typography,
  CardContent,
  useMediaQuery,
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useGetOrganizationBrandSettingsQuery, useUpdateOrganizationBrandSettingsMutation } from 'src/services/organisation/organisation.service';

import Iconify from 'src/components/iconify';
import FontSelector from 'src/components/font-selector/font-selector';
import ImageSelector from 'src/components/image-selector/image-selector';

import ExportFieldMapper, { ExportFieldMapperHandle } from './export-field-mapper';

interface ExportSettingsFormData {
  exporter_header_image_id?: string | null;
  exporter_footer_image_id?: string | null;
}

export default function ExportSettingsCard() {

  const { t } = useTranslate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { organization } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fieldMapperRef = useRef<ExportFieldMapperHandle>(null);

  const [headerImage, setHeaderImage] = useState<{ id: string, url: string } | null>(null);
  const [footerImage, setFooterImage] = useState<{ id: string, url: string } | null>(null);
  const [font, setFont] = useState<string | null>(null);

  const { currentData: settingsData } = useGetOrganizationBrandSettingsQuery(
    organization.id
  );

  const [updateBrandSettings, { isLoading: isUpdating }] = useUpdateOrganizationBrandSettingsMutation();

  useEffect(() => {
    if (settingsData) {

      if (settingsData.exporter_header_image) {
        setHeaderImage({ id: settingsData.exporter_header_image?.id, url: settingsData.exporter_header_image?.public_path });
      }

      if (settingsData.exporter_footer_image) {
        setFooterImage({ id: settingsData.exporter_footer_image?.id, url: settingsData.exporter_footer_image?.public_path });
      }

      setFont(settingsData.exporter_font);

    }
  }, [settingsData]);

  const onSubmit = useCallback(async (data: ExportSettingsFormData) => {
    try {

      const fieldMapValid = await fieldMapperRef.current?.validate();
      if (!fieldMapValid) {
        return;
      }

      const fieldMap = await fieldMapperRef.current?.getFieldMap();

      await updateBrandSettings({
        organizationId: organization?.id,
        exporter_header_image_id: data.exporter_header_image_id,
        exporter_footer_image_id: data.exporter_footer_image_id,
        exporter_cover_field_mapping: fieldMap,
        exporter_font: font
      }).unwrap();

      enqueueSnackbar(t('admin-organization.settings.export_settings.api.update.success'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('admin-organization.settings.export_settings.api.update.default_error'), { variant: 'error' });
    }
  }, [organization, updateBrandSettings, t, font]);

  const handleExportSettingsUpdate = useCallback(async () => {
    await onSubmit({
      exporter_header_image_id: headerImage?.id ?? null,
      exporter_footer_image_id: footerImage?.id ?? null
    });
  }, [headerImage, footerImage, onSubmit]);

  const onEditPhoto = useCallback((type: string, fileData: { url: string, id: string }) => {

    if (type === 'exporter_header_image' && fileData.id !== headerImage?.id) {
      setHeaderImage(fileData);

      onSubmit({
        exporter_header_image_id: fileData.id
      });
    }
    else if (type === 'exporter_footer_image' && fileData.id !== footerImage?.id) {
      setFooterImage(fileData);

      onSubmit({
        exporter_footer_image_id: fileData.id
      });
    }

  }, [headerImage, footerImage, onSubmit]);

  const onFontSelected = useCallback((fontIdentifier: string) => {
    setFont(fontIdentifier);
  }, [setFont]);

  if (!organization) {
    return (
      <Box sx={{ width: '100%' }}>
        <Card >
          <CardContent>
            <Typography>{t('common.loading')}</Typography>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Card
        sx={{
          width: '100%',
          position: 'relative',
          boxShadow: 0
        }}
      >
        <CardHeader
          avatar={
            <Iconify
              icon="lets-icons:arhive-export"
              sx={{
                width: 30,
                height: 30,
                color: theme.palette.primary.main,
              }}
            />
          }
          title={
            <Typography variant="h6" component="div">
              {t('admin-organization.settings.export_settings.title')}
            </Typography>
          }
        />

        <CardContent>
          <Stack spacing={3} sx={{ px: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mb: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                  {t('admin-organization.settings.export_settings.labels.cover_field_mapping')}
                </Typography>

                <ExportFieldMapper ref={fieldMapperRef} fieldMap={settingsData?.exporter_cover_field_mapping ?? []} />

              </Grid>

              <Grid item xs={12} >
                <Typography variant="subtitle2" gutterBottom>
                  {t('admin-organization.settings.brand_settings.labels.font_settings')}
                </Typography>
                <FontSelector onFontSelected={onFontSelected} initialSelection={settingsData?.exporter_font ?? null} />
              </Grid>

              <Grid item xs={12} sx={{ mb: 3 }}>
                <ImageSelector
                  fieldLabel={t('admin-organization.settings.export_settings.labels.export_header_image')}
                  onChange={(fileData) => onEditPhoto('exporter_header_image', fileData)}
                  initialImage={headerImage ?? undefined}
                  onRemove={() => setHeaderImage(null)}
                  restrictions={{
                    maxWidth: 1000,
                    maxHeight: 300,

                    minWidth: 600,
                    minHeight: 50
                  }}
                />
              </Grid>

              <Grid item xs={12} sx={{ mb: 3 }}>
                <ImageSelector
                  fieldLabel={t('admin-organization.settings.export_settings.labels.export_footer_image')}
                  onChange={(fileData) => onEditPhoto('exporter_footer_image', fileData)}
                  initialImage={footerImage ?? undefined}
                  onRemove={() => setFooterImage(null)}
                  restrictions={{
                    maxWidth: 1000,
                    maxHeight: 300,

                    minWidth: 600,
                    minHeight: 50
                  }}
                />
              </Grid>

              <Grid item xs={12} sx={{ mb: 3 }} />

            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                onClick={handleExportSettingsUpdate}
                disabled={isUpdating}
                loading={isUpdating}
              >
                {t('common.update')}
              </LoadingButton>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}