import { enqueueSnackbar } from 'notistack';
import React, { useMemo, useState, useEffect } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    Box,
    Card,
    Grid,
    Paper,
    useTheme,
    TextField,
    Pagination,
    Typography,
    CardContent,
    CircularProgress
} from '@mui/material';

import { useTranslate } from 'src/locales';

// Types
interface Font {
    identifier: string;
    family: string;
    variants: string[];
    files: Record<string, string>;
    category: string;
    subsets: string[];
}

interface FontSelectorProps {
    onFontSelected: (result: string) => void;
    initialSelection?: string | null;
    itemsPerPage?: number;
}


const FontSelector: React.FC<FontSelectorProps> = ({
    onFontSelected,
    initialSelection = null,
    itemsPerPage = 3
}) => {
    const { t } = useTranslate();
    const theme = useTheme();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [allFonts, setAllFonts] = useState<Font[]>([]);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [selectedFont, setSelectedFont] = useState<string | null>(null);
    const [previewText, setPreviewText] = useState<string>(t('components.font_selector.default_preview_text'));
    const [fontStyles, setFontStyles] = useState<Record<string, React.CSSProperties>>({});
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        if (initialSelection) {
            setSelectedFont(initialSelection);
            setCurrentPage(1);
            setSearchQuery('');
        }
    }, [initialSelection]);

    // Google Fonts API key - store this securely in your environment variables
    const API_KEY = import.meta.env.VITE_FONTS_API_KEY as string;

    // Load all fonts once on component mount
    useEffect(() => {
        const loadAllFonts = async () => {
            setInitialLoading(true);

            try {
                // Use the Google Fonts Developer API to get all fonts
                const response = await fetch(
                    `https://www.googleapis.com/webfonts/v1/webfonts?key=${API_KEY}&sort=popularity`
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch fonts');
                }

                const data = await response.json();

                let standardizedFonts = data.items.map((font: any) => ({
                    identifier: generateFontIdentifier(font),
                    family: font.family,
                    variants: font.variants,
                    category: font.category,
                    subsets: font.subsets
                }));

                // unique fonts by identifier
                standardizedFonts = standardizedFonts.filter((font: any, index: number, self: any) =>
                    index === self.findIndex((f: any) => (
                        f.identifier === font.identifier
                    ))
                );

                setAllFonts(standardizedFonts || []);
            } catch (error) {
                console.error('Error fetching fonts:', error);
                enqueueSnackbar(t('components.font_selector.error_loading_fonts'), { variant: 'error' });
            } finally {
                setInitialLoading(false);
            }
        };

        loadAllFonts();
    }, [API_KEY, t]);

    // Filter fonts based on search query
    const filteredFonts = useMemo(() => {
        if (!searchQuery.trim()) {
            return allFonts;
        }

        const query = searchQuery.toLowerCase().trim();
        return allFonts.filter(font =>
            font.family.toLowerCase().includes(query) ||
            font.category.toLowerCase().includes(query)
        );
    }, [allFonts, searchQuery]);

    const displayedFonts = useMemo(() => {
        const results = [...filteredFonts];
        const selectedFontIndex = filteredFonts.findIndex((font: Font) => font.identifier === initialSelection);
        if (selectedFontIndex !== -1) {
            const currentSelectedFont = allFonts[selectedFontIndex];
            results.splice(selectedFontIndex, 1);
            results.unshift(currentSelectedFont);
        }

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return results.slice(startIndex, endIndex);
    }, [filteredFonts, initialSelection, currentPage, itemsPerPage, allFonts]);

    // Calculate pagination
    const totalPages = useMemo(() =>
        Math.max(1, Math.ceil(filteredFonts.length / itemsPerPage)),
        [filteredFonts, itemsPerPage]
    );

    // Reset to first page when search query changes
    useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery]);

    // Load font styles for displayed and selected fonts
    useEffect(() => {
        const loadFontStyles = async () => {
            // Get unique list of all fonts we need to display (displayed + selected)
            const uniqueFontFamilies = new Set([
                ...displayedFonts.map(font => font.family)
            ]);

            // Find the font objects for all fonts we need to load
            const fontsToLoad = allFonts.filter(font => uniqueFontFamilies.has(font.family));

            // Build a Google Fonts URL for all fonts we need to preview
            const fontFamilies = fontsToLoad.map(font =>
                `family=${encodeURIComponent(font.family).replace(/%20/g, '+')}`
            ).join('&');

            if (fontFamilies) {
                // Create link element to load the fonts
                const link = document.createElement('link');
                link.rel = 'stylesheet';
                link.href = `https://fonts.googleapis.com/css2?${fontFamilies}&display=swap`;

                // Add the link to the document head
                document.head.appendChild(link);

                // Create a styles object for each font
                const newFontStyles: Record<string, React.CSSProperties> = {};
                fontsToLoad.forEach(font => {
                    newFontStyles[font.family] = { fontFamily: `'${font.family}', ${font.category}` };
                });

                setFontStyles(prevStyles => ({ ...prevStyles, ...newFontStyles }));

                // Clean up function to remove the link when component unmounts
                return () => {
                    document.head.removeChild(link);
                };
            }

            return undefined;
        };

        if (displayedFonts.length > 0) {
            loadFontStyles();
        }
    }, [displayedFonts, selectedFont, allFonts]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const toggleFontSelection = (font: Font) => {
        // only one can be selected
        // if already selected, it will be unselected
        if (selectedFont === font.identifier) {
            setSelectedFont(null);
            onFontSelected('');
        } else {
            setSelectedFont(font.identifier);
            onFontSelected(font.identifier);
        }
    };

    const generateFontIdentifier = (fontData: any) => {
        const family = fontData.family.replace(/\s+/g, '+');

        // Check if there are any italic variants
        const hasItalicVariants = fontData.variants.some((variant: string) =>
            variant === 'italic' || variant.includes('italic')
        );

        // Process variants
        const processedVariants = fontData.variants.reduce((acc: string[], variant: string) => {
            // Handle "regular" and "italic" special cases
            if (variant === 'regular') {
                acc.push(hasItalicVariants ? '0,400' : '400');
            } else if (variant === 'italic') {
                acc.push('1,400');
            } else if (variant.includes('italic')) {
                // Convert weight+italic (e.g., "500italic" -> "1,500")
                const weight = variant.replace('italic', '');
                acc.push(`1,${weight}`);
            } else {
                // Normal weights (e.g., "500" -> "0,500")
                acc.push(hasItalicVariants ? `0,${variant}` : variant);
            }
            return acc;
        }, []);

        // Sort variants numerically by both italic flag and weight
        processedVariants.sort((a: string, b: string) => {
            const [aItalic = '0', aWeight] = a.includes(',') ? a.split(',') : ['0', a];
            const [bItalic = '0', bWeight] = b.includes(',') ? b.split(',') : ['0', b];

            // First compare italic flags
            const italicComparison = Number(aItalic) - Number(bItalic);
            if (italicComparison !== 0) return italicComparison;

            // If italic flags are the same, compare weights
            return Number(aWeight) - Number(bWeight);
        });

        // Construct the final string
        const variantsString = processedVariants.join(';');

        return `family=${family}${hasItalicVariants ? ':ital,wght' : ':wght'}@${variantsString}`;
    }

    const handlePreviewTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPreviewText(e.target.value);
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 1200, mx: 'auto' }}>
            <Grid container spacing={3}>
                {/* Left side - Font browser */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, mb: 3 }}>
                        <Box sx={{ display: 'flex', mb: 2 }}>
                            <TextField
                                fullWidth
                                placeholder={t('components.font_selector.search_placeholder')}
                                value={searchQuery}
                                onChange={handleSearch}
                                InputProps={{
                                    startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />
                                }}
                                variant="outlined"
                            />
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <TextField
                                fullWidth
                                label={t('components.font_selector.preview_text_label')}
                                value={previewText}
                                onChange={handlePreviewTextChange}
                                variant="outlined"
                                size="small"
                            />
                        </Box>

                        {initialLoading && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                                <CircularProgress />
                                <Typography sx={{ ml: 2 }}>
                                    {t('components.font_selector.loading_fonts')}
                                </Typography>
                            </Box>
                        )}

                        {
                            (filteredFonts.length === 0 && !initialLoading) && (
                                <Box sx={{ p: 4, textAlign: 'center' }}>
                                    <Typography>
                                        {t('components.font_selector.no_search_results')}
                                    </Typography>
                                </Box>
                            )}

                        {
                            (!initialLoading && filteredFonts.length > 0) &&
                            (
                                <>
                                    <Grid container spacing={2}>
                                        {displayedFonts.map(font => (
                                            <Grid item xs={12} key={font.family}>
                                                <Card
                                                    variant="outlined"
                                                    sx={{
                                                        cursor: 'pointer',
                                                        transition: 'all 0.2s',
                                                        '&:hover': {
                                                            boxShadow: 3
                                                        },
                                                        border: selectedFont === font.identifier
                                                            ? `2px solid ${theme.palette.primary.main}`
                                                            : undefined
                                                    }}
                                                    onClick={() => toggleFontSelection(font)}
                                                >
                                                    <CardContent>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                                            <Typography variant="h6">
                                                                {font.family}
                                                            </Typography>
                                                            {selectedFont === font.identifier && (
                                                                <CheckCircleIcon color="primary" />
                                                            )}
                                                        </Box>
                                                        <Typography
                                                            sx={{
                                                                ...fontStyles[font.family],
                                                                fontSize: '1.2rem',
                                                                minHeight: '60px',
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            {previewText}
                                                        </Typography>
                                                        <Box sx={{ mt: 1 }}>
                                                            <Typography variant="caption" color="text.secondary">
                                                                {font.category} • {font.variants.length} {t('components.font_selector.styles')}
                                                            </Typography>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>

                                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                                        <Typography variant="body2" sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                            {t('components.font_selector.showing_results').replace(
                                                '{start}',
                                                ((currentPage - 1) * itemsPerPage + 1).toString()
                                            ).replace(
                                                '{end}',
                                                Math.min(currentPage * itemsPerPage, filteredFonts.length).toString()
                                            ).replace(
                                                '{total}',
                                                filteredFonts.length.toString()
                                            )}
                                        </Typography>
                                        <Pagination
                                            count={totalPages}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                            color="primary"
                                        />
                                    </Box>
                                </>
                            )
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default FontSelector;