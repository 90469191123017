import { t } from 'i18next';
import { useState, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Stack,
  Button,
  Dialog,
  Tooltip,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@mui/material';

import { useAppDispatch } from 'src/store/store';
import generateAnimation from 'src/assets/lottie/writing.json';
import { openFeatureAccessModal } from 'src/store/slices/billing/billingSlice';
import { useImproveApplicationProfileMutation } from 'src/services/organisation/organisation.service';

import Editor from 'src/components/editor';
import Iconify from 'src/components/iconify';
import AnimationContainer from 'src/components/animation/container';

import { useFeatures } from 'src/sections/billing/usage/hooks/use-features';

import { FeatureType } from 'src/types/subscription.types';

interface Props {
  profileId: string;
  onUseGeneration: (summary: string) => void;
}

const ProfileSummaryGenerator = ({ profileId, onUseGeneration }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [description, setSummary] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { canAccess } = useFeatures();

  const dispatch = useAppDispatch();

  const [generateProfileSummary, { isLoading, isError }] = useImproveApplicationProfileMutation();

  const handleGenerateSummary = useCallback(async () => {

    if (!profileId) return;

    setError(null);

    if (!canAccess(FeatureType.PROFILE_SUMMARY_AI_FEATURE_ENABLED)) {
      handleClose();
      dispatch(openFeatureAccessModal({
        featureType: FeatureType.PROFILE_SUMMARY_AI_FEATURE_ENABLED
      }));
    }

    try {
      const response = await generateProfileSummary({
        candidate_profile_id: profileId,
      }).unwrap();
      setSummary(response.summary);
    } catch (err) {
      setError(err.data.message);
    }
  }, [profileId, generateProfileSummary, canAccess, dispatch]);

  const handleClose = () => {
    setIsOpen(false);
    setSummary(null);
    setError(null);
  };

  const onSummaryChange = (value: string) => {
    setSummary(value);
  }

  const handleUseGeneration = useCallback(() => {
    if (description) {
      handleClose();
      onUseGeneration(description);
    }
  }, [description, onUseGeneration]);

  return (
    <>
      <IconButton
        disabled={!canAccess(FeatureType.PROFILE_SUMMARY_AI_FEATURE_ENABLED)}
        onClick={() => {
          setIsOpen(true);
          handleGenerateSummary();
        }}
      >
        <Tooltip title={t('components.profile_summary_generator.tooltip')}>
          <Iconify icon="mingcute:ai-line" />
        </Tooltip>
      </IconButton>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {t('components.profile_summary_generator.title')}
        </DialogTitle>

        <DialogContent>
          <Stack justifyContent="center" alignItems="center">
            {
              !isLoading && (
                <Alert severity="warning" sx={{ width: '100%', mb: 2 }} icon={false}>
                  {t('components.profile_summary_generator.disclaimer')}
                </Alert>
              )
            }

            {
              isLoading && (
                <Stack direction="column" justifyContent="center" alignItems="center" sx={{ width: 400 }}>
                  <AnimationContainer json={generateAnimation} loop />
                  <Typography variant='caption'>
                    {t('components.profile_summary_generator.generating')}
                  </Typography>
                </Stack>
              )
            }

            {
              isError && (
                <Typography color="error" sx={{ p: 2 }}>
                  {error}
                </Typography>
              )
            }

            {
              (description && !isLoading && !isError) && (
                <Editor
                  value={description}
                  onChange={onSummaryChange}
                />
              )
            }
          </Stack>
        </DialogContent>
        <DialogActions>

          {
            (description && !isLoading && !isError) && (
              <Button
                variant='contained'
                color='primary'
                onClick={handleUseGeneration}
                startIcon={<Iconify icon="line-md:circle-to-confirm-circle-transition" />}
              >
                {t('components.profile_summary_generator.use_generation')}
              </Button>
            )
          }

          <LoadingButton
            onClick={handleGenerateSummary}
            variant='contained'
            loading={isLoading}
            startIcon={<Iconify icon="streamline:ai-redo-spark" />}
          >
            {t('components.profile_summary_generator.regenerate')}
          </LoadingButton>
          <Button
            onClick={handleClose}
          >
            {t('common.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileSummaryGenerator;