import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, { Crop } from 'react-image-crop';
import React, { useRef, useState, useEffect } from 'react';

import { Stack } from '@mui/system';
import { Add, Delete } from '@mui/icons-material';
import {
  Box,
  Paper,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useUploadFileMutation } from 'src/services/files/file.service';

interface ImageData {
  url: string;
  id: string;
}

interface ImageSelectorProps {
  initialImage?: ImageData;
  onChange: (imageData: ImageData) => void;
  onRemove: () => void;
  fieldLabel: string;
  restrictions?: {
    maxWidth?: number;
    maxHeight?: number;
    minWidth?: number;
    minHeight?: number;
  };
}

const ImageSelector: React.FC<ImageSelectorProps> = ({
  initialImage,
  onChange,
  onRemove,
  fieldLabel,
  restrictions = {}
}) => {


  const [selectedImage, setSelectedImage] = useState<ImageData | null>(null);

  const [tempImage, setTempImage] = useState<string | null>(null);

  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    width: 90,
    height: 90,
    x: 0,
    y: 0
  });

  const cropDialogControl = useBoolean();

  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const imgRef = useRef<HTMLImageElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [uploadFile] = useUploadFileMutation();

  const { t } = useTranslate();

  useEffect(() => {
    if (initialImage) {
      setSelectedImage(initialImage);
    }
  }, [initialImage]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setTempImage(reader.result as string);
        cropDialogControl.onTrue();
      };
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = '';
    }
  };

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;

    // Default values if restrictions are not provided
    const maxWidth = restrictions.maxWidth || width;
    const maxHeight = restrictions.maxHeight || height;
    const minWidth = restrictions.minWidth || 50; // Default minimum width
    const minHeight = restrictions.minHeight || 50; // Default minimum height

    // Set initial crop to respect dimensions
    const aspectRatio = maxWidth / maxHeight;
    let cropWidth = Math.min(Math.max(width, minWidth), maxWidth);
    let cropHeight = Math.min(Math.max(height, minHeight), maxHeight);

    if (cropWidth / cropHeight > aspectRatio) {
      cropWidth = cropHeight * aspectRatio;
    } else {
      cropHeight = cropWidth / aspectRatio;
    }

    // Ensure crop is not smaller than minimum dimensions
    cropWidth = Math.max(cropWidth, minWidth);
    cropHeight = Math.max(cropHeight, minHeight);

    // Center the crop
    const x = (width - cropWidth) / 2;
    const y = (height - cropHeight) / 2;

    setCrop({
      unit: 'px',
      width: cropWidth,
      height: cropHeight,
      x,
      y
    });
  };

  const getCroppedImg = (
    image: HTMLImageElement,
    cropState: Crop
  ): Promise<{ url: string; blob: Blob }> => new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      canvas.width = cropState.width || 0;
      canvas.height = cropState.height || 0;

      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(
          image,
          (cropState.x || 0) * scaleX,
          (cropState.y || 0) * scaleY,
          (cropState.width || 0) * scaleX,
          (cropState.height || 0) * scaleY,
          0,
          0,
          cropState.width || 0,
          cropState.height || 0
        );
      }

      canvas.toBlob((blob) => {
        if (blob) {
          const url = URL.createObjectURL(blob);
          resolve({ url, blob });
        }
      }, 'image/jpeg');
    });

  const handleCropComplete = (cropState: Crop) => {
    setErrorMessage('');

    // Check max dimensions
    if (restrictions.maxWidth && (cropState.width || 0) > restrictions.maxWidth) {
      setErrorMessage(t('components.image_selector.error_max_width_exceeded'));
      return;
    }

    if (restrictions.maxHeight && (cropState.height || 0) > restrictions.maxHeight) {
      setErrorMessage(t('components.image_selector.error_max_height_exceeded'));
      return;
    }

    // Check min dimensions
    if (restrictions.minWidth && (cropState.width || 0) < restrictions.minWidth) {
      setErrorMessage(t('components.image_selector.error_min_width_required'));
      return;
    }

    if (restrictions.minHeight && (cropState.height || 0) < restrictions.minHeight) {
      setErrorMessage(t('components.image_selector.error_min_height_required'));
      return;
    }

    setCompletedCrop(cropState);
  };

  const handleCropDialogClose = () => {
    cropDialogControl.onFalse();
    setTempImage(null);
  };

  const handleCropDialogConfirm = async () => {
    if (imgRef.current && completedCrop) {
      try {
        // Check if crop dimensions are within restrictions
        if (
          (restrictions.maxWidth && (completedCrop.width || 0) > restrictions.maxWidth) ||
          (restrictions.maxHeight && (completedCrop.height || 0) > restrictions.maxHeight) ||
          (restrictions.minWidth && (completedCrop.width || 0) < restrictions.minWidth) ||
          (restrictions.minHeight && (completedCrop.height || 0) < restrictions.minHeight)
        ) {
          alert(t('components.image_selector.error_dimensions_invalid'));
          return;
        }

        const { url, blob } = await getCroppedImg(imgRef.current, completedCrop);

        const uploadResult = await uploadImage(blob);

        const newImageData: ImageData = {
          url: uploadResult.url,
          id: uploadResult.id
        };

        setSelectedImage(newImageData);
        onChange(newImageData);
        cropDialogControl.onFalse();
        setTempImage(null);
      } catch (error) {
        console.error('Error creating cropped image:', error);
      }
    }
  };

  const uploadImage = async (blob: Blob): Promise<ImageData> => {
    try {
      const file = new File([blob], `${Date.now()}-${Math.random().toString(36).slice(2)}.jpg`, { type: 'image/jpeg' });

      const uploadedFile = await uploadFile({
        file,
        type: 'image' as any,
      }).unwrap();

      return { url: uploadedFile.public_path, id: uploadedFile.id };
    } catch (e) {
      console.error('Error uploading image:', e);
      throw e;
    }
  };

  const handleRemoveClick = () => {
    setSelectedImage(null);
    onRemove();
  };

  const handleSelectClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const validateCrop = (cropState: Crop, image: HTMLImageElement | null): boolean => {
    if (!image) return false;

    // Check if crop dimensions are within restrictions
    const maxWidthValid = !restrictions.maxWidth || (cropState.width || 0) <= restrictions.maxWidth;
    const maxHeightValid = !restrictions.maxHeight || (cropState.height || 0) <= restrictions.maxHeight;
    const minWidthValid = !restrictions.minWidth || (cropState.width || 0) >= restrictions.minWidth;
    const minHeightValid = !restrictions.minHeight || (cropState.height || 0) >= restrictions.minHeight;

    return maxWidthValid && maxHeightValid && minWidthValid && minHeightValid;
  };

  const getInstructionText = () => {
    let text = t('components.image_selector.crop_instructions');

    // Replace placeholders with actual values if they exist
    if (restrictions.maxWidth) {
      text = text.replace('{maxWidth}', restrictions.maxWidth.toString());
    } else {
      text = text.replace('{maxWidth}', 'unlimited');
    }

    if (restrictions.maxHeight) {
      text = text.replace('{maxHeight}', restrictions.maxHeight.toString());
    } else {
      text = text.replace('{maxHeight}', 'unlimited');
    }

    // Add min dimensions info if provided
    if (restrictions.minWidth || restrictions.minHeight) {
      text += ` ${  t('components.image_selector.min_dimensions_instruction')}`;

      if (restrictions.minWidth) {
        text = text.replace('{minWidth}', restrictions.minWidth.toString());
      } else {
        text = text.replace('{minWidth}', '0');
      }

      if (restrictions.minHeight) {
        text = text.replace('{minHeight}', restrictions.minHeight.toString());
      } else {
        text = text.replace('{minHeight}', '0');
      }
    }

    return text;
  };

  return (
    <Box sx={{ mb: 2, width: '100%' }}>
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        {fieldLabel}
      </Typography>

      {selectedImage ? (
        <Box sx={{ position: 'relative', display: 'inline-block', width: '100%' }}>
          <Paper
            elevation={2}
            sx={{
              display: 'inline-flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              height: 'auto',
              borderRadius: 1,
            }}
          >
            <Box
              component="img"
              src={selectedImage.url}
              alt={t('components.image_selector.selected_image')}
              sx={{
                width: '100%',
                objectFit: 'contain',
                borderRadius: 1
              }}
            />

            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                p: 1,
                display: 'flex',
                gap: 1,
              }}
            >
              <IconButton
                size="small"
                onClick={handleRemoveClick}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  },
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          </Paper>
        </Box>
      ) : (
        <Button
          variant="outlined"
          startIcon={<Add />}
          onClick={handleSelectClick}
        >
          {t('components.image_selector.select_image')}
        </Button>
      )}

      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />

      <Dialog
        open={cropDialogControl.value}
        onClose={handleCropDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{t('components.image_selector.crop_image')}</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            {getInstructionText()}
          </Typography>
          <Stack justifyContent="center" alignItems="center" spacing={2} sx={{ mb: 2 }}>

            {errorMessage && (
              <Typography variant="body2" color="error">
                {errorMessage}
              </Typography>
            )}

            {tempImage && (
              <ReactCrop
                crop={crop}
                onChange={(newCrop) => setCrop(newCrop)}
                onComplete={handleCropComplete}
                style={{ maxHeight: '70vh' }}
              >
                <img
                  ref={imgRef}
                  src={tempImage}
                  alt={t('components.image_selector.preview')}

                  style={{
                    maxWidth: '100%',
                    maxHeight: '70vh'
                  }}
                  onLoad={handleImageLoad}
                />
              </ReactCrop>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCropDialogClose}>
            {t('components.image_selector.cancel')}
          </Button>
          <Button
            onClick={handleCropDialogConfirm}
            color="primary"
            disabled={!completedCrop || !validateCrop(completedCrop, imgRef.current)}
          >
            {t('components.image_selector.apply')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ImageSelector;